import ButtonPrimary from '../style-components/ButtonPrimary';
import gps_game from '../../img/gps-game.png';

function FooterColumnMiddle({ texts }) {
  const gpsGameText = texts && texts["our-gps-game"] ? texts["our-gps-game"].text : '';
  const funGameText = texts && texts["very-fun-game-and-cool-stuff"] ? texts["very-fun-game-and-cool-stuff"].text : '';
  const tryItOutText = texts && texts["try-it-out!"] ? texts["try-it-out!"].text : '';

  return (
    <div className="footer-column middle">
      <h3 className="cardo">{gpsGameText}</h3>
      <p className='padding-20-top'>{funGameText}</p>
      <img className="padding-10-top" src={gps_game} alt="" style={{ marginBottom: "-4px" }} />
      <div className="button-container">
        <ButtonPrimary text={tryItOutText} icon="ArrowRightUp" link="https://connect.leplace.online/storyline-talesofreval" />
      </div>
    </div>
  );
}

export default FooterColumnMiddle;
