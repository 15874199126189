function OurTeamCard({bgimage, title, email, phone}) {
  return (
    <div className="team" style={{backgroundImage: `url(${bgimage})`}}>
    <div className="team-info">
        <h5 className="blue-text">{title}</h5>
        <a className = "mail" href={`mailto:${email}`}>{email}</a>
        <a className="phone bold" href={`tel:${phone}`}>{phone}</a>
    </div>
</div>
  )
}

export default OurTeamCard
