import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addText, editText, getTexts, reset } from '../features/texts/textSlice';
import Spinner from '../components/Spinner';
import { FaRegEdit, FaRegTrashAlt, FaRegSave } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import AddText from '../components/AddText';
import { toast } from 'react-toastify';


function EditTexts() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { all_texts, isLoading, isError, message } = useSelector(
      (state) => state.texts
  )

  const [editting, setEditting] = useState(-1);
  const [inputName, setInputName] = useState(null);
  const [inputCategory, setInputCategory] = useState('');
  const [inputText, setInputText] = useState('');
  const [inputTranslation, setInputTranslation] = useState('');

  useEffect(() => {
      if (isError) {
          toast.error(message);
      }
      return () => {
          dispatch(reset());
      };
  }, [all_texts, isError, message, dispatch]);

  useEffect(() => {
      dispatch(getTexts());
  }, []);

  const handleAdd = (text) =>{
      dispatch(addText(text));
  }

  const saveText = () => {
    if(editting !== -1){
      const newText = {
          _id: all_texts[editting]._id,
          text_name: inputName,
          category: inputCategory,
          text: inputText,
          text_estonian: inputTranslation
      }
      dispatch(editText(newText));
      setEditting(-1);
      toast.success("Text edited successfully");
    }
  }

  const handleRowClick = (text, index) => {
    setEditting(index === editting ? -1 : index);
    setInputName(text.text_name);
    setInputCategory(text.category);
    setInputText(text.text);
    setInputTranslation(text.text_estonian);
  }

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  if(isLoading || !all_texts){
      return <Spinner />
  }


  return (
    <div>
      <div className='edit-events'>
        <div className="edit-events-title" style={{width: "49.5%"}}>
          <h3 className='admin-heading padding-20-bottom'>Edit Texts</h3>
        </div>
        <AddText addText={handleAdd}/>
      
      <table className='edit-text-table'>
        <thead>
          <tr>
            <th className="category">Category</th>
            <th className="text">Text</th>
            <th className = "translation">Translation</th>
          </tr>
        </thead>
        <tbody>
          {all_texts && all_texts.length > 0 ? (
                all_texts.map((text, index) => (
                  <>
                    <tr key={index} className={editting === index ? "no-hover" : ""} onClick={editting === index ? saveText : () => handleRowClick(text, index)}>
                      <td className="title">{editting === index ? <div className='text-edit-save'><FaRegSave style={{marginBottom: -2}}/> Save</div> : text.category}</td>
                      <td className="text">{editting === index ? "" : text.text}</td>
                      <td className="translation">{editting === index ? "" : text.text_estonian}</td>
                    </tr>
                    {editting === index ? 
                      <tr key={index}>
                        <td className="input"><textarea type="text" value = {inputCategory} onChange={(e) => setInputCategory(e.target.value)}/></td>
                        <td className="input"><textarea type="text" value = {inputText} onChange={(e) => setInputText(e.target.value)}/></td>
                        <td className="input"><textarea type="text" value = {inputTranslation} onChange={(e) => setInputTranslation(e.target.value)}/></td>
                      </tr>
                      : null}
                  </>
                  
                  

                ))
          ) : null}
          {/* {texts.map((text, index) => (
            <tr key={index}>
                <td className="title">{text.category}</td>
                <td className="year">{text.text}</td>
                <td className="year">{text.text_estonian}</td>
            </tr>
          ))} */}
        </tbody>
      </table>
    </div>
    </div>
  )
}

export default EditTexts;
