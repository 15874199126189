import person1 from '../img/person1.png';
import person2 from '../img/person2.png';
import person3 from '../img/person3.png';
import OurTeamCard from './style-components/OurTeamCard';

function OurTeamList() {

  return (
      <div className="our-team-grid">
        <OurTeamCard bgimage={person3} title="Wilhelm Weltwanderer" email="wilhelm@talesofreval.ee" phone="+372 5981 8480" />
        <OurTeamCard bgimage={person2} title="Gregorius Durchnacht" email="gregorius@talesofreval.ee" phone="+372 5342 0389" />
        <OurTeamCard bgimage={person1} title="Cedric Stimmlich" email="cedric@talesofreval.ee" phone="+372 5560 4421" />
      </div>
  )
}

export default OurTeamList
