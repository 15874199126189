import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { addDate, addMultipleDates } from '../features/tour/tourSlice';
import 'react-datepicker/dist/react-datepicker.css';

function AddDate({ closeAdd }) {
  const [singleDate, setSingleDate] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [length, setLength] = useState('');
  const [frequency, setFrequency] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  const dispatch = useDispatch();

  const generateTimeOptions = () => {
    const times = [];
    const start = 10 * 60; // 10:00 AM in minutes
    const end = 18 * 60; // 6:00 PM in minutes
    for (let i = start; i <= end; i += 30) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      times.push(time);
    }
    return times;
  };

  const handleSubmit = () => {
    if (!selectedDate) {
      alert('Please select a date');
      return;
    }

    const localDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000).toISOString().split('T')[0];

    if (singleDate) {
      dispatch(addDate({
        date: localDate,
        time: selectedTime
      }));
    } else {
      dispatch(addMultipleDates({
        date: localDate,
        time: selectedTime,
        frequency,
        length
      }));
    }

    setSelectedDate(null);
    setSelectedTime('');
    setFrequency('');
    setLength('');
    closeAdd();
  };

  return (
    <div>
      <div className="type-selector">
        <div className="type-selector-item">
          <input type="radio" id="single-date" name="date-type" value="single-date" checked={singleDate} onChange={() => setSingleDate(true)} />
          <label className="padding-10-left text-light" htmlFor="single-date">Single Date</label>
        </div>
        <div className="type-selector-item">
          <input type="radio" id="multiple-dates" name="date-type" value="multiple-dates" checked={!singleDate} onChange={() => setSingleDate(false)} />
          <label className="padding-10-left text-light" htmlFor="multiple-dates">Multiple Dates</label>
        </div>
        {singleDate ? (
          <div className="date-input">
            <label className="text-light" htmlFor="date">Select a Date:</label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select a date"
              className="date-picker-input"
            />
            {selectedDate && (
              <>
                <label className="text-light" htmlFor="time">Select a Time:</label>
                <select
                  className='basic-select'
                  id="time"
                  name="time"
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                >
                  <option value="">Select a time</option>
                  {generateTimeOptions().map((time) => (
                    <option key={time} value={time}>{time}</option>
                  ))}
                </select>
              </>
            )}
          </div>
        ) : (
          <div className="date-input">
            <form>
              <label htmlFor="date">Select a Date:</label>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="yyyy-MM-dd"
                placeholderText="Select a date"
                className="date-picker-input"
              />
              <label htmlFor="frequency">Frequency:</label>
              <select
                id="frequency"
                name="frequency"
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
              >
                <option value="">Select frequency</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
              <label htmlFor="time">Select a Time:</label>
              <select
                id="time"
                name="time"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
              >
                <option value="">Select a time</option>
                {generateTimeOptions().map((time) => (
                  <option key={time} value={time}>{time}</option>
                ))}
              </select>
              <label htmlFor="length">Length:</label>
              <select
                id="length"
                name="length"
                value={length}
                onChange={(e) => setLength(e.target.value)}
              >
                <option value="">Select length</option>
                <option value="2-weeks">2 Weeks</option>
                <option value="3-weeks">3 Weeks</option>
                <option value="1-month">1 Month</option>
                <option value="2-months">2 Months</option>
                <option value="3-months">3 Months</option>
                <option value="4-months">4 Months</option>
                <option value="5-months">5 Months</option>
                <option value="6-months">6 Months</option>
              </select>
            </form>
          </div>
        )}
        <div className="padding-5-top padding-10-bottom">
          <button className="basic-button" onClick={handleSubmit}>Add</button>
        </div>
      </div>
    </div>
  );
}

export default AddDate;
