import HeaderBar from "./HeaderBar"
import logo from '../../img/logo.svg'
import { useNavigate } from "react-router-dom"

function DesktopHeader({ourServicesOpen, setOurServicesOpen, setShowBookNow, texts, misc_texts}) {

  const navigate = useNavigate()

  const navhome = () => {
    setOurServicesOpen(false)
    navigate("/")
  }

  return (
    <div className="header-container">
        <div className="header-logo" onClick={navhome}>
            <img src={logo} alt="" />
        </div>
        <HeaderBar texts={texts} misc_texts={misc_texts} setShowBookNow={setShowBookNow} ourServicesOpen={ourServicesOpen} setOurServicesOpen={setOurServicesOpen}/>
    </div>
  )
}

export default DesktopHeader
