import MenuItem from "../style-components/MenuItem";
import { useNavigate } from "react-router-dom";

function AdminBar({ setShowBookNow, ourServicesOpen, setOurServicesOpen, texts, home_texts }) {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
    setOurServicesOpen(false);
  };

  const homeText = texts && texts["home"] ? texts["home"].text : null;

  return (
    <div className="header-bar">
      <div className="header-navigation">
        <div className="navigation-items">
          <MenuItem title={homeText} onClick={() => handleClick("/")} />
        </div>
        <div className="admin-actions">
          <MenuItem title="Texts" onClick={() => handleClick("/admin-edit-texts")} />
          <MenuItem title="Events" onClick={() => handleClick("/admin-edit-events")} />
          <MenuItem title="Services" onClick={() => handleClick("/admin-edit-services")} />
          <MenuItem title="Bookings" onClick={() => handleClick("/admin-bookings")} />
          <MenuItem title="Logout" onClick={() => handleClick("/logout")} />
        </div>
      </div>
    </div>
  );
}

export default AdminBar;
