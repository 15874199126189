import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { getDates, reset } from '../features/tour/tourSlice'
import DateActions from '../components/DateActions'
import ButtonPrimary from '../components/style-components/ButtonPrimary'
import AddDate from '../components/AddDate'

function AdminBookings() {
  const dispatch = useDispatch()
  const { dates, isError, isLoading, message } = useSelector((state) => state.tour)

  const [selectedDate, setSelectedDate] = useState(null)
  const [addNew, setAddNew] = useState(false)

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    return () => {
      dispatch(reset())
    }
  }, [dates, isError, isLoading, message, dispatch])

  useEffect(() => {
    dispatch(getDates())
  }, [dispatch])

  const isDateAvailable = (date) => {
    const formattedDate = date.toDateString();
    return dates.some(d => new Date(d.date).toDateString() === formattedDate);
  }

  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      return !isDateAvailable(date)
    }
    return false
  }

  return (
    <div className="container">
      <div className='admin-bookings'>
        <div className="padding-10-bottom">
          <ButtonPrimary text={addNew ? 'Cancel' : 'Add Date'} onClick={() => setAddNew(!addNew)}/>
        </div>
        {addNew && <AddDate closeAdd={() => setAddNew(false)} dates={dates}/> }
        <Calendar
          onChange={setSelectedDate}
          value={selectedDate}
          tileDisabled={tileDisabled}
        />
        {selectedDate && (
          <DateActions selectedDate={selectedDate} dates={dates} closePopup={() => setSelectedDate(null)}/>
        )}
      </div>
    </div>
  )
}

export default AdminBookings
