import { useEffect, useState } from 'react'
import ButtonPrimary from './style-components/ButtonPrimary'
import { useDispatch, useSelector } from 'react-redux'
import { FaTimes } from 'react-icons/fa'
import { removeDate  } from '../features/tour/tourSlice'
import { getPeople } from '../features/email/emailSlice'

function DateActions({ closePopup, dates, selectedDate }) {
  const [selectedTime, setSelectedTime] = useState('')
  const [removeDay, setRemoveDay] = useState(false)
  const [seePeople, setSeePeople] = useState(false)
  const [messageBox, setMessageBox] = useState('')
  const dispatch = useDispatch()
  
  const { people } = useSelector(state => state.email)

  useEffect(() => {
    if (seePeople && selectedDate) {
      const selectedDateObject = dates.find(dateObj => new Date(dateObj.date).toDateString() === selectedDate.toDateString())
      if (selectedDateObject) {
        dispatch(getPeople(selectedDateObject._id))
      }
    }
  }, [seePeople, selectedDate, dates, dispatch])

  const handleRemoveOne = () => {
    if (removeDay) {
      // Remove whole day
      const data = {
        date: selectedDate.toDateString(),
        removeDay: true,
        removeAll: false,
        message: messageBox
      }
      dispatch(removeDate(data))
    } else {
      // Remove one time
      const data = {
        date: selectedDate,
        time: selectedTime,
        removeDay: false,
        removeAll: false,
        message: messageBox
      }
      dispatch(removeDate(data))
    }
    closePopup()
    window.location.reload()
  }

  const handleRemoveAll = () => {
    if (removeDay) {
      // Remove whole day
      const data = {
        date: selectedDate,
        removeDay: true,
        removeAll: true,
        message: messageBox
      }
      dispatch(removeDate(data))
    } else {
      // Remove one time
      const data = {
        date: selectedDate,
        time: selectedTime,
        removeDay: false,
        removeAll: true,
        message: messageBox
      }
      dispatch(removeDate(data))
    }
    closePopup()
    window.location.reload()
  }

  const times = dates
    .filter(d => new Date(d.date).toDateString() === selectedDate.toDateString())
    .map(d => d.time)

  console.log(selectedDate)

  return (
    <div className="date-actions">
    
      {seePeople ? 
        <div className="date-actions-popup">
          <ButtonPrimary text='Close' onClick={closePopup} />
          {people && people.map(person => (
            <div key={person._id} className="flex gap-10">
              <p>{person.name}</p>
              <p>{person.email}</p>
            </div>
          ))}
        </div>
      :
      <div className='date-actions-popup'>
        <p>{selectedDate.toDateString()}</p>
        <div className="type-selector-item">
          <input
            type="radio"
            id="single-date"
            name="date-type"
            value="single-date"
            checked={removeDay}
            onChange={() => setRemoveDay(true)}
          />
          <label className="padding-10-left" htmlFor="single-date">Remove whole day</label>
        </div>
        <div className="type-selector-item">
          <input
            type="radio"
            id="specific-time"
            name="date-type"
            value="specific-time"
            checked={!removeDay}
            onChange={() => setRemoveDay(false)}
          />
          <label className="padding-10-left" htmlFor="specific-time">Remove specific time</label>
        </div>
        {!removeDay &&
          <select
            name="time"
            id="time"
            className="basic-select"
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
          >
            <option value="">Select a time</option>
            {times.map((time) => (
              <option key={time} value={time}>{time}</option>
            ))}
          </select>
        }
        
        <textarea name="" id="" style={{height: "6rem", resize: "none", padding:"10px", font:"inherit"}} placeholder="Message" value={messageBox} onChange={(e) => setMessageBox(e.target.value)} />

        <div className='date-actions-close'>
          <FaTimes size={20} onClick={closePopup} />
        </div>
        <div className="flex gap-10">
          <ButtonPrimary text='Remove this week' onClick={handleRemoveOne} />
          <ButtonPrimary text='Remove all weeks' onClick={handleRemoveAll} />
          <ButtonPrimary text='See people' onClick={() => setSeePeople(!seePeople)} />
        </div>
      </div>
    
      }
    </div>
  )
}

export default DateActions
