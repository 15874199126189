import SocialButton from '../style-components/SocialButton';

function FooterColumnRight({ texts }) {
  const followUsText = texts && texts["follow-us:"] ? texts["follow-us:"].text : '';
  const contactUsText = texts && texts["contact-us:"] ? texts["contact-us:"].text : '';
  const taxAddressText = texts && texts["tax-address"] ? texts["tax-address"].text : '';

  return (
    <div className="footer-column side right mobile-20-top">
      <h3 className="cardo padding-20-bottom">{followUsText}</h3>
      <div className="social-buttons">
        <SocialButton icon="Facebook" text="Facebook" link="https://www.facebook.com/TalesofReval/" />
        <SocialButton icon="Instagram" text="Instagram" link="https://www.instagram.com/talesofreval/" />
        <SocialButton icon="TripAdvisor" text="TripAdvisor" link="https://www.tripadvisor.co.uk/Attraction_Review-g274958-d14768095-Reviews-Tales_of_Reval-Tallinn_Harju_County.html" />
        <SocialButton icon="AirBnB" text="AirBnB" link="https://www.airbnb.co.uk/experiences/1096623" />
      </div>
      <h3 className="cardo padding-20-top contact-us-footer">{contactUsText}</h3>
      <p className='padding-20-top line-height-spaced'>
        <a className="dark" href="mailto:info@talesofreval.ee">info@talesofreval.ee</a>
        <br />
        <a className="dark " href="tel:+372 5555 5555">+372 5560 4421</a>
      </p>
      <p className="padding-20-top">
        OÜ Satsang <br />
        Reg no. 14443936 <br />
        {taxAddressText}
      </p>
    </div>
  );
}

export default FooterColumnRight;
