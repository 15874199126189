import React, {useEffect} from 'react'
import HomeLanding from '../components/HomeLanding'
import OurServices from '../components/OurServices'
import { useDispatch, useSelector } from 'react-redux';
import OurTeam from '../components/OurTeam'
import { getHomeTexts, getMiscTexts, reset } from '../features/texts/textSlice';
import Spinner from '../components/Spinner';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

function Home() {

  const dispatch = useDispatch();

  const { home_texts, isLoading, isError, message } = useSelector(
      (state) => state.texts
  )
  
  const { misc_texts } = useSelector(
      (state) => state.texts
  )

  useEffect(() => {
      if (isError) {
          toast.error(message);
      }
      if(localStorage.getItem("language") === null){
          localStorage.setItem("language", "en");
      }

      return () => {
          dispatch(reset());
      };
      
  }, [home_texts, misc_texts, isError, message, dispatch]);

  useEffect(() => {
      dispatch(getHomeTexts());
      dispatch(getMiscTexts());
  }, []);

  if(isLoading || !home_texts || !misc_texts){
    return <Spinner />
  }

  return (
    <div className="home-page">
      <Helmet>
        <title>Home - Tales of Reval</title>
        <meta name="description" content="Experience the most authentic medieval tours in Tallinn. Discover unique live experiences and historical adventures with Tales of Reval." />
        <meta name="keywords" content="Medieval Tours in Tallinn, Historical Experiences in Estonia, Interactive Medieval Experiences, Tallinn Guided Tours, Live Medieval Shows, Top Rated Tallinn Tours, Unique Tallinn Experiences, Authentic Tallinn Tours, Best Tallinn Attractions, Tallinn Tour Company" />
      </Helmet>
      <HomeLanding texts={home_texts}/>
      <div className="container">
        <OurServices texts={misc_texts}/>
        <OurTeam texts={misc_texts}/>
      </div>
      
    </div>
  )
}

export default Home
